.todolistandtimer {
    display: flex;
}

.showtodosbutton {
    display: block;
    width: 60px;
    height: 60px;
    font-size: xx-large;
    margin-left: 30px;
    margin-top: 25px;
    background: transparent;
    color: white;
    border: 1px solid white;
    border-radius: 4px;
}

.showtodosbutton:hover {
    cursor: pointer;
    background: orange;
    transition: 0.5s;
}

/* 
    EVERYTHING BELOW IS WORKING CSS TIMER CODE
*/

.countdown-wrapper {
    /**display: flex;**/
    --offset: 163;
    --offset-calc: calc(calc(var(--offset)) * 1px);
    justify-content: center;
    align-items: center;
    position: absolute;
    margin-left: -200px;
    left: calc(50% + var(--offset-calc));

}

.session-counter {
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    margin-left: auto;
    margin-right: auto;
}

.countdown-timer {
    width: 400px;
    aspect-ratio: 1;
    border-radius: 50%;
    /* border: 2px solid orange; */
    box-shadow: black;
    font-family: "DM Sans";
    --f: 68;
    --f-size: calc(calc(var(--f)) * 1px);
    font-size: var(--f-size);
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    --x: 0;
    --deg: calc(calc(1 - var(--x)) * 360deg);
    background: conic-gradient(orange 0deg, orange var(--deg), var(--deg), grey 360deg);
}

.inner-circle {
    width: 375px;
    aspect-ratio: 1;
    border-radius: 50%;
    /* background: linear-gradient(45deg, rgb(58, 15, 88), #da61ff, green); */
    background: linear-gradient(45deg, blue, plum);
    background-size: 400% 400%;
    /* animation: gradient_anim 60s ease infinite; */
    display: flex;
    font-weight: bold;
    text-align: center;
    justify-content: center;
    align-items: center;
}

.timertext {
    display: block;
}

.countdown-timer::before {
    content: '';
    position: absolute;
    inset: 6px;
    border-radius: 50%;
    background-color: var(--bg);
}

.timeinputform {
    background: transparent;
    border: none;
    border-bottom: 1px solid white;
    color: white;
    outline: none;
    font-size: 68px;
    height: min-content;
    width: 78px;
    text-align: center;
}

.settimebutton {
    display: block;
    margin-top: 12px;
    background: transparent;
    color: white;
    height: 2em;
    border: 1px solid white;
    border-radius: 2px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    z-index: 1;
}

.settimebutton:hover {
    background-color: orange;
    cursor: pointer;
    z-index: 3;
}
  
.countdown-timer span {
    position: relative;
    z-index: 1;
}

.countdown-buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
    margin-bottom: auto;
    background: transparent;
}

.playbutton {
    width: 100px;
    height: 100px;
    border: 3px solid white;
    border-radius: 50%;
    background: transparent;
    padding-left: 7px;

    display: flex;
    justify-content: center;
    align-items: center;

    font-size: 24px;
    font-family: "DM Sans";
    /* margin-right: auto;
    margin-left: 3%; */
}

.fastforwardbutton {
    width: 80px;
    height: 80px;
    color: white;
    border: 3px solid white;
    border-radius: 50%;
    background: transparent;

    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;

    font-size: 84px;
    font-weight: 200;
    font-family: "DM Sans";
    margin-right: 50px;
    /* margin-left: auto;
    margin-right: 3%; */
}

.endsessionbutton {
    width: 80px;
    height: 80px;
    color: white;
    border: 3px solid white;
    border-radius: 50%;
    background: transparent;

    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;

    font-size:45px;
    font-weight: 400;
    font-family: "DM Sans";
    margin-left: 50px;
    /* margin-right: auto; */
}

.playbutton:hover, .fastforwardbutton:hover, .endsessionbutton:hover {
    border: none;
    background-color: orange;
    cursor: pointer;
    transition: 0.5s;
}

#startBtn {
    width: 40px;
    height: 40px;
    box-sizing: border-box;
    border-style: solid;
    border-width: 20px 0px 20px 40px;
    border-color: transparent transparent transparent #ffffff;
}

#pauseBtn {
    width: 40px;
    height: 40px;
    box-sizing: border-box;
    border-style: double;
    border-width: 0px 0px 0px 37px;
    border-color: #ffffff;
}

#fastforwardicon {
    transform: translateY(-6px);
}

span {
    margin-left: 3px;
    margin-right: 3px;
    color: white;
}

@keyframes gradient_anim {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}