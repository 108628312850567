.studylogitem {
    display: flexbox;
    background: rgb(136, 0, 255);

    /* background: transparent; */
    color: white;
    width: 500px;
    height: min-content;
    font-family: "San Francisco";
    overflow-y: auto;
    border-radius: 4px;
    border: 1px solid orange;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: -2px;
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 10px;
}

.studylogitemheader:hover {
    cursor: pointer;
}