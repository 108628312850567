/* .news {
    display: flex;
    color: white;
}

.title {
    margin-left: auto;
    margin-right: auto;
    color: inherit;
} */


@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@500&family=Roboto:wght@500&display=swap");

/* * {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
} */

.News{
    background: transparent;
    top: 0;
    min-height: 100vh;
    display: flex;
}

/* body {
    font-family: "dm-sans", sans-serif;
} */


/* p {
    font-family: "Roboto", sans-serif;
    color: var(--secondary-text-color);
} */

a {
    text-decoration: none;
    
}

/* ul {
    list-style: none;
} */

.flex {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.container {
    margin:auto;
    max-width:80%;
    margin-inline: auto;
    overflow: hidden;
}
.cards-container {
  margin-left: 15%;
  max-width:80%;
  justify-content: space-between;
  flex-wrap: wrap;
  row-gap: 20px;
  align-items: start;
  padding-top: 60px;
  margin-bottom: 20px;
}