.App {
  min-width: 100vw;
  min-height: 100vh;
  width: min-content;
  margin: 0;
  padding: 0;
  background: linear-gradient(45deg, purple 15%, blue);
  background-attachment: fixed;
  background-size: 300% 300%;
  /* animation: gradient_anim 60s ease infinite; */
}

.navbar {
  background-color: transparent;
  color: white;
  display: flex;
  justify-content: left;
  align-items: center;
  gap: 2rem;
  padding: 10px 25px;
  min-width: 800px;
}

.navbar li, .navbar .navbar-item {
  color: inherit;
  text-decoration: none;
  height: 100%;
  display: flex;
  padding: .25rem;
  font-family: "San Francisco";
  font-size: 18px;
}

.flmdlogo {
  border-radius: 50%;
  opacity: 1;
}

.navbar-left {
  display: flex;
  margin-left: 30px;
  margin-right: auto;
  align-items: center;
}

#todolist-nav {
  margin-left: auto;
}

#news {
  margin-right: 30px;
}

.navbar-right {
  display: flex;
  margin-left: 20px;
  align-items: center;
}

.navbar-item:hover {
  /* background-color: white; */
  color: orange;
  cursor: pointer;
  /* border-bottom: 1px solid orange; */
  transition: 0.2s;
  opacity: 1;
}

#todoList {
  margin-left: auto;
  margin-right: auto;
  width: 600px;
  background: rgb(111, 0, 209);
  padding-top: 25px;
  margin-top: 25px;
  max-height: 80vh;
  overflow-y: auto;
  /* mix-blend-mode:difference; */
}

#addTaskForm {
  display: flex;
  margin-left: auto;
  margin-right: auto;
  margin-top: 25px;
  margin-bottom: 25px;
  align-items: center;
  justify-content: center;
  height: min-content;
}

#taskList {
  padding-top: 25px;
  background: rgb(111, 0, 209); /*transparent*/
  /* background-size: 400% 400%; */
  border-radius: 4px;
  padding: 10px;
}

#addTaskForm input, #addTaskForm textarea {
  margin-left: 10px;
  margin-right: 10px;
  display: block;
  /* border-radius: 4px; */
  outline: none;
}

#taskTitle, #taskDescription, #taskDeadline {
  background: transparent;
  border: none;
  margin-bottom: 10px;
  font-size: medium;
  font-family: "San Francisco";
  color: white;
}

#taskTitle, #taskDescription, #taskDeadline {
  position: relative;
  left: 20px;
}

.form-left {
  margin-right: 25px;
}

.form-right {
  margin-top: 0px;
}

#taskTitle, #taskDescription {
  border: 1px solid white;
  border-radius: 4px;
  height: min-content;
}

#taskTitle {
  height: 30px;
  font-size: large;
  width: 250px;
}

#taskDescription {
  min-height: 50px;
  min-width: 400px;
}

/* #taskDescription {
  resize: none;
} */

#taskDeadline {
  width: 37px;
  height: 30px;
  text-align: left;
  justify-content: left;
  border: 1px solid white;
  border-radius: 4px;
}

/* #taskDeadlineDays {
  margin-left: -5px;
} */

#taskDeadlineContainer {
  display: flex;
  position: relative;
  right: 180px;
  bottom: 14px;
}

/* #addTaskButton {
  --r: 50;
  --w: 40;
  --h: 40;
  --radius-percent: calc(calc(var(--r)) * 1%);
  font-size: medium;
  background-color: transparent;
  margin-left: 2px;
  color: white;
  border: 1px solid;
  border-radius: var(--radius-percent);
  height: var(calc(calc(var(--h)) * 1px));
  width: var(calc(calc(var(--w)) * 1px));
} */

#addTaskButton {
  background: transparent;
  color: white;
  border-radius: 5px;
  border: 1px solid white;
  position: relative;
  top: 3px;
}

#addTaskButton:hover {
  cursor: pointer;
  /* background-color: orange; */
  background: orange;
  transition: 0.5s;
}

.createtaskbutton {
  display: flex;
  font-size: 20px;
  font-weight: lighter;
}

.creataskbutton:hover {
  color: orange;
}

.plussign {
  font-size: 20px;
}



.taskdivider {
  width: 90%;
  opacity: 0.5;
  /* border: none; */
}

::placeholder {
  color: white;
}

::-webkit-calendar-picker-indicator {
  filter: invert(1);
}

#bottom-spacer {
  height: 25px;
  width: 100%
}

#emptymessage {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  color: white;
}

@keyframes gradient_anim {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@font-face {
  font-family: "San Francisco";
  font-weight: 400;
  src: url("https://applesocial.s3.amazonaws.com/assets/styles/fonts/sanfrancisco/sanfranciscodisplay-regular-webfont.woff");
}

/* 
EXPERIMENTAL
*/