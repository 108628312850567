.timertodolist {
    width: 300px;
    max-height: 70vh;
    padding-bottom: 25px;
    overflow-y: scroll;
    position: relative;
    background-color: rgb(111, 0, 209);
    padding: 10px;
    border-radius: 4px;
    color: white;
    margin-top: 25px;
    margin-left: 28px;
    margin-right: 10px;
}