.studylog {
    display: block;
    color: white;
    width: 600px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 35px;
}

.studylist {
    background: rgb(111, 0, 209);
    border-radius: 4px;
    padding: 25px;
    max-height: 75vh;
    overflow-y: auto;
}

.title {
    margin-left: auto;
    margin-right: auto;
    color: inherit;
    text-align: center;
}