.task {
    display: flexbox;
    justify-content: center;
    align-items: center;
    margin-top: 5px;
    margin-bottom: 5px;
    margin-left: auto;
    margin-right: auto;
}

.content {
    background: transparent/**rgb(212, 138, 150)**/;
    color: white;
    width: 500px;
    --height: 78;
    --height-intermediate: calc(calc(var(--height)) * 1px);
    height: min-content;
    font-family: "San Francisco";
    overflow-y: auto;
    border-radius: 10px;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: -2px;
}

.content:hover {
    cursor: pointer;
}

.metadata {
    float: right;
    margin-left: 10px;
}

.taskdeadline {
    margin-top: 5px;
    text-align: center;
}

.buttons {
    display: flex;
    visibility: hidden;
    /* margin-bottom: 5px; */
    margin-right: 4px;
    position: relative;
    top: 3px;
}

.completeButton {
    display: block;
    background: transparent;
    width: 33px;
    font-size: medium;
    text-align: center;
    color: white;
    border-radius: 4px;
    border: none;
}

.deleteButton {
    display: block;
    width: 33px;
    font-size: medium;
    text-align: center;
    background: transparent;
    color: white;
    border-radius: 4px;
    border: none;
    position: relative;
    top: 1px;
}

.editButton {
    display: block;
    background: transparent;
    width: 33px;
    font-size: medium;
    text-align: center;
    color: white;
    border-radius: 4px;
    border: none;
}

.completeButton:hover, .deleteButton:hover, .editButton:hover, .taskdownarrow:hover {
    cursor: pointer;
    color: white;
}

.completeButton:hover {
    background: green;
    transition: 0.5s;
}

.editButton:hover, .taskdownarrow:hover {
    background: orange;
    transition: 0.5s;
}

.deleteButton:hover {
    background: red;
    transition: 0.5s;
}

.tasktitle {
    margin-top: 5px;
    margin-left: 5px;
}

.taskdateadded {
    margin-top: -10px;
    margin-left: 5px;
}

.taskdownarrow {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    border-radius: 50%;
    width: 20px;
    aspect-ratio: 1;
    position: relative;
    bottom: 10px;
    margin-bottom: -4px;
}

.taskdescription {
    display: none;
    margin-top: 10px;
    /* margin-left: 5px;
    margin-right: 5px; */
    font-family: "San Francisco";
    color: white;
    width: 500px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    left: 5px;
    /* color: orange; */
    /* mix-blend-mode:difference; */
}

@font-face {
    font-family: "San Francisco";
    font-weight: 400;
    src: url("https://applesocial.s3.amazonaws.com/assets/styles/fonts/sanfrancisco/sanfranciscodisplay-regular-webfont.woff");
}