.timertodolistitem {
    display: flex;
    /* color: white; */
    border-radius: 4px;
    box-shadow: 1px;
    /* border: 1px solid orange; */
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: auto;
    margin-right: auto;
    padding: 10px;
    overflow-x: auto;
    width: 80%;
    z-index: 2;
}

.timertodolistitemcompletebutton {
    height: 18px; 
    aspect-ratio: 1;
    margin-top: auto;
    margin-bottom: auto; 
    margin-right: 10px; 
    border: 1px solid white;
    border-radius: 50%;
    text-align: center;
    z-index: 3;
}

.timertodolistitemcompletebutton:hover {
    background-color: white;
    color: orange;
    cursor: pointer;
    transition: 0.2s;
}

.timertodotitle {
    /* color: white; */
    margin-left: 10px;
}

.timertododeadline {
    color: white;
    margin-right: 5px;
    float: right;
}

.timertodolistitem:hover {
    cursor: pointer;
}
