.dropdown {
    width: 13em;
    max-width: 10%;
    margin: 50px auto 0;
    display: flex;
    position: fixed;
    
  }
  
  .dropdown-btn {
    background: transparent;
    border: 2px solid white;
    font-size: 18px;
    margin-left: 20%;
    width: 100%;
    min-width: 20px;
    color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.7em 0.5em;
    border-radius: 0.5em;
    cursor: pointer;
    transition: 0.5s;
    transition-delay: calc(60ms * var(--delay));
  }

  .dropdown-btn span{
    overflow-x: auto;
  }

  .dropdown-btn:hover{
    background: orange;
  }
  
  .arrow {
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 6px solid #fff;
    transition: transform ease-in-out 0.3s;
  }
  
  .dropdown-content {
    list-style: none;
    position: absolute;
    top: 3.2em;
    width: 80%;
    min-width: 20px;
    margin-left: 20%;
    margin-top: 10px;
  }
  
  .dropdown-content li {
    background: transparent;
    border-radius: 0.5em;
    position: relative;
    border: 1px solid white;
    transition: 0.5s;
    transition-delay: calc(60ms * var(--delay));
    right: 40px;
    padding: 0;
    margin: 0;
    box-sizing: border-box;
  }

  .dropdown-content li a{
    overflow-x: auto;
  }
  
  .dropdown-content.menu-open li {
    left: 0;
  }
  
  .arrow.arrow-rotate {
    transform: rotate(180deg);
  }
  
  .dropdown-content li:hover {
    background: orange;
    cursor: pointer;
  }
  
  .dropdown-content li a {
    display: block;
    padding: 0.7em 0.5em;
    color: #fff;
    margin: 0.1em 0;
    text-decoration: none;
  }



/*.css-umysuv {
    border-top: 1px solid #ebebeb;
    border-bottom: 1px solid #000;
    
}
.css-397oyn {
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 5px 0 4px;
    margin: 0 20px;
}
.css-cwdrld {
    display: inline-block;
    font-family: nyt-franklin,helvetica,arial,sans-serif;
    font-size: 0.75rem;
    line-height: 1rem;
    font-weight: 500;
}
.css-1wjnrbv {
    border-radius: 3px;
    display: inline-block;
    padding: 4px;
    -webkit-transition: background 0.6s ease;
    transition: background 0.6s ease;
    vertical-align: middle;
}*/